import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({
  lang,
  urls,
  seo: { title, description, keywords, externalHreflangs, additionalMeta, canonicalUrl },
  fontsToPreload,
}) => {
  const {
    siteSettings,
    brandSettings,
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query SettingsQuery {
      siteSettings {
        siteName
        lang
      }
      brandSettings {
        brandName
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteTitle = siteSettings.siteName || '';
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
    ...additionalMeta.map(({ key, value }) => ({ name: key, content: value })),
  ];

  const link = [
    ...urls.map(({ lang: hreflang, href }) => ({
      rel: 'alternate',
      href: `${siteUrl}${href}`,
      hrefLang: hreflang.replace('_', '-'),
    })),
    ...externalHreflangs.map(({ key, value }) => ({
      rel: 'alternate',
      href: value,
      hrefLang: key,
    })),
    ...(canonicalUrl
      ? [
          {
            rel: 'canonical',
            href: `${process.env.GATSBY_CANONICAL_LINK}${canonicalUrl}`,
          },
        ]
      : []),
    ...(fontsToPreload || []).map(({ publicURL }) => ({
      rel: 'preload',
      href: publicURL,
      as: 'font',
      crossOrigin: 'true',
    })),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang }} {...{ title, titleTemplate, meta, link }}>
      {process.env.GATSBY_NODE_ENV === 'production' ? (
        <meta name="robots" data-react-helmet="true" />
      ) : null}
    </Helmet>
  );
};

export default Seo;
